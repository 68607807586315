<template>
  <div class="corpuses" id="select-corpus">
    <Multiselect
      v-model="values"
      :options="options"
      :multiple="true"
      :limit="2"
      group-values="corpora"
      :close-on-select="false"
      group-label="group"
      :group-select="true"
      placeholder="Type to search"
      track-by="id"
      label="name"
    >
      <span slot="noResult">No results found</span>
    </multiselect>
  </div>
</template>

<script>
// import config from '@/config.js'
import Multiselect from 'vue-multiselect'

const corporaOptions = [
  {
    group: "Corpus du territoire d'oïl",
    corpora: [
      { name: "Chartes de l'Anjou et de la Touraine (1229–1300)", id: "a887c04e-4c28-4742-8f31-7b99fe0018a7" },
      { name: "Documents de Normandie (1250–1300)", id: "0ccfbafb-b6a7-40f0-9532-7a3820b66b5f" },
      { name: "Chartes de l’Oise (1241–1286)", id: "9c45bcb1-8be8-4ac4-9b43-81eb1c892f05" },
      { name: "Chartes de Douai (1204–1270)", id: "03e3c557-befc-4807-bdfb-f2777d98c2c2" },
      { name: "Chartes de la Flandre (1224–1270)", id: "a9d4bfd6-4220-4cb7-806d-75a03372ff27" },
      { name: "Chartes du Hainaut (1236–1270)", id: "5442b344-a5b5-43ab-9449-bedb9a2397a9" },
      { name: "Chartes de la chancellerie royale (1241–1300)", id: "e9bef1f6-ef02-4ee5-8340-6a80fa501792" },
      { name: "Actes de la prévôté de Paris (1265–1300)", id: "a5551162-0789-4e04-b001-5b21f7646c41" },
      { name: "Chartes de l’Aube, de la Seine-et-Marne et de l’Yonne (1230–1271)", id: "641f1305-53e3-4bc5-a1f3-e5462b29a793" },
      { name: "Chartes de la Marne (1234–1272)", id: "b7fcc709-6c53-4101-bec4-7f7ae1c6b473" },
      { name: "Chartes de la Haute-Marne (1232–1275)", id: "ce28bec7-e35a-4c46-af40-944e3f2e22d9" },
      { name: "Chartes de la Meuse (1225–1270)", id: "73afc015-5b18-4cc0-a871-5c9672631da7" },
      { name: "Chartes de la Meurthe-et-Moselle (1232–1265)", id: "16d89d6d-5e08-4343-a495-8bfdba3d50c4" },
      { name: "Chartes de la Moselle (1215–1271)", id: "fe949a36-cd61-4707-a9db-f0bceac76ed4" },
      { name: "Chartes des Vosges (1235–1275)", id: "e0271c0b-b9ae-43a9-bb1b-10eb7922dace" },
      { name: "Chartes de la Nièvre (1289–1330)", id: "2fc5eeb5-7b83-496a-99f2-8d2dc50c8f30" },
      { name: "Chartes de la Côte d'Or (1239–1270)", id: "c53e45ad-172f-4445-8731-8d9eba2022c6" },
      { name: "Chartes de la Saône-et-Loire (1257–1331)", id: "e3ea6bbf-5071-47e9-834f-517c73840e78" },
      { name: "Chartes de la Haute-Saône (1242–1300)", id: "f2030746-6e70-4c26-88ea-9a27d0edbb94" },
    ]
  }, {
    group: "Corpus du territoire francoprovençal",
    corpora: [
      { name: "Chartes du Doubs I (1233–1260)", id: "2d39e8d8-565f-4270-9bce-cf59d5695814" },
      { name: "Chartes du Doubs II (1261–1271)", id: "01c6b77f-0541-4137-97a1-17570417f481" },
      { name: "Chartes du dép. Jura (1243–1296)", id: "9998d973-a173-4214-a8dd-80b00de6e314" },
      { name: "Documents des cantons du Jura et de Berne (1244–1395)", id: "8cc7ec8d-8b23-493e-9a84-8f134d7fbd39" },
      { name: "Chartes du canton de Neuchâtel (1238–1379)", id: "b6db288b-f5d7-43c6-a1c2-d459897d1798" },
      { name: "Documents de Fribourg (1293–1496)", id: "f960071b-ac13-4d06-8d80-01e01b034632" },
      { name: "Documents de Vaud et de Genève (1247–1406)", id: "7fb66c78-fbf4-40cc-b670-4e002102152e" },
      { name: "Documents de l'Ain (1271–1465)", id: "08c2fc3b-95dc-4dc7-bc55-ef5eef17a377" },
      { name: "Documents du Lyonnais (1225–1425)", id: "b9810668-1dce-4576-992e-5b5800ce470f" },
      { name: "Documents du Forez (1260–1410)", id: "69e58cc4-c337-46a5-94a3-cb210a191fa7" },
      { name: "Comptes consulaires de Grenoble (1338–1340)", id: "04fe016a-0022-4f8d-a1a8-0d44e37055bd" },
      { name: "Somme du code dauph. (ca 1210)", id: "622f7793-ece8-4e7b-b590-b7287375dd46" },
    ]
  }, {
    group: "Corpus du territoire occitan",
    corpora: [
      { name: "Chartes occitanes (1078–1200)", id: "6883986b-1938-4544-8cc7-d46fb01c4fbc" },
      { name: "Documents des Hautes-Alpes (ca 1200–1568)", id: "b0ee247a-31d2-4a6c-afdf-984e7070c397" },
      { name: "Documents des Alpes-de-Haute-Provence (1332–1570)", id: "8b9c1462-cc2f-499e-9e97-2508c3adb76c" },
      { name: "Documents des Alpes-Maritimes (ca 1070–1630)", id: "d216ae1e-f7af-4f8d-ac05-e480bd505bd5" },
      { name: "Documents des Bouches-du-Rhône: Marseille (1319–1521)", id: "6c6741f1-fd0d-48ea-a088-931ebd079140" },
      { name: "Documents des Bouches-du-Rhône: Aix (1387–1547)", id: "54c35fbe-f3b5-45c4-9784-7fe164ea51c9" },
      { name: "Documents des Bouches-du-Rhône: Arles (1318–1532)", id: "ed074489-d118-4bf9-8151-ea84613acc95" },
      { name: "Chartes de la Saintonge (1219–1300)", id: "b6016385-1536-4951-a72a-0616d93993df" },
      { name: "Chartes du Poitou (1238–1300)", id: "ab7db098-fc6e-4445-af3f-1dbe5918b022" },
    ]
  }
]

export default {
  name: 'CorpusSelectPI',
  data() {
    return {
      options: corporaOptions,
      values: [],
    }
  },
  components: {
    Multiselect,
  },
  watch: {
    values() {
      this.$emit('select', this.values)
    },
  },
}
</script>
