<template>
  <div>
    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4" align-h="end">
        <b-col cols="4" class="p-0">
          <PageTitle v-bind:title="this.$t('DocLing_Texts')" />
        </b-col>
        <b-col cols="8" class="text-right">
          <b-form-group label="Corpus" class="small-title">
            <CorpusSelectPI class="mb-3" @select="selectCorpus" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <LegamDataTables v-if="config.editable" v-bind="$data" :additionalData="tableAdditionalData" />
          <LegamDataTablesClientSide v-else v-bind="$data" @click="openDetails" ref="PITable" :additionalDataFilter="tableAdditionalDataFilter" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 70px;
  }

  * >>> .tdEdition {
    width: 70%;
  }

  * >>> .tdDateNum {
    width: 20%;
  }
</style>

<script>
  import PageTitle from '@/components/PageTitle.vue'
  import router from '@/router'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import CorpusSelectPI from '@/components/Document/CorpusSelectPI.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import tdColAction from "@/components/tdColAction"
  import tdColUUID from '@/components/tdColUUID.vue'
  import config from '@/config.js'

  export default {
    name: 'Documents',
    data() {
      return {
        columns: [
          { title: 'ID', field: 'documentId', tdComp: tdColUUID, sortable: true, searchable: true },
          { title: this.$t('SigleEdition'), field: 'documentEditionSigleLegam', sortable: true, searchable: true, thClass: 'tdEdition' },
          { title: this.$t('DateNum'), field: 'manuscriptDateNum', sortable: true, searchable: true, thClass: 'tdDateNum' },
          { title: 'Corpus', field: 'corpus.name', sortable: true, searchable: false },
          { title: 'Action', field: 'action', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function (row){
              return [
                { text: null, icon: 'list-ul', id: row.documentId, to: {
                  name: 'DocumentDetails', params: {
                    id: row.documentId
                  }}
                }
              ]
            }
          }
        ],
        columnsPI: [
          { title: 'Edition Sigle', field: 'documentEditionSigleLegam', sortable: true, searchable: true, thClass: 'tdEdition' },
          { title: 'Date Num', field: 'manuscriptDateNum', sortable: true, searchable: true, thClass: 'tdDateNum' },
          { title: 'Corpus', field: 'corpus.name', sortable: true, searchable: false },
        ],
        url: config.editable ? '/documents/datatables': '/documents',
        key: 1,
        searchableByColumn: true,
        config: config,
        selectedCorpora: [],
      }
    },
    computed: {
      tableAdditionalData(){
        return {
          'selectedCorpora': this.selectedCorpora,
        }
      },
    },
    methods: {
      openDetails(data) {
        router.push(`/document/${data.documentId}`)
      },
      selectCorpus(corpora) {
        this.selectedCorpora = corpora.map(corpus => corpus.id)
        this.key++
        if (!config.editable) {
          this.$refs.PITable.sortPageFilter()
        }
      },
      tableAdditionalDataFilter(data) {
        if (this.selectedCorpora.length) {
          data = data.filter(row => this.selectedCorpora.includes(row.corpus.id))
        }
        return data
      },
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      CorpusSelectPI,
    }
  }
</script>
